import type { Cart } from "@packages/sdk";
import { isBefore, isSameDay } from "date-fns";

export const useTrialType = ({
  trial_end_date,
  intro_offer,
}: Pick<Cart, "trial_end_date" | "intro_offer">): "free" | "paid" | "none" =>
  !!intro_offer
    ? "paid"
    : !trial_end_date ||
        isBefore(new Date(trial_end_date), new Date()) ||
        isSameDay(new Date(trial_end_date), new Date())
      ? "none"
      : "free";
