"use client";

import { LDProvider } from "launchdarkly-react-client-sdk";
import type { ComponentPropsWithoutRef, ReactNode } from "react";

import { HallowABFlag } from "../types";
import { ABContextProvider } from "./ABContextProvider";

export type ABProviderProps = Partial<
  ComponentPropsWithoutRef<typeof LDProvider>
> & {
  children: ReactNode;
};

export const ABProvider = ({ children, ...props }: ABProviderProps) => (
  <LDProvider
    clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_KEY}
    reactOptions={{ useCamelCaseFlagKeys: false }}
    timeout={3}
    flags={{
      [HallowABFlag.WebMediumOnboarding]: "excluded",
      [HallowABFlag.WebStrikethrough]: "excluded",
    }}
    {...props}
  >
    <ABContextProvider>{children}</ABContextProvider>
  </LDProvider>
);
