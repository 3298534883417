import { Link } from "@packages/i18n";
import { getURLFromDeeplink } from "@packages/sdk";
import { useMemo } from "react";

import type { StyleXArray } from "../../../../types";
import type { TextProps } from "../../../_base";
import { Text } from "../../../_base";
import { ExpandableText } from "../ExpandableText";

const makeLinks = (
  str: string,
  regex: RegExp,
  styleXArray: StyleXArray,
  formatter: (str: string) => string = (str) => str,
) => {
  let matches: RegExpExecArray;
  let lastIndex = 0;
  const result = [];

  while ((matches = regex.exec(str)) !== null) {
    result.push(str.substring(lastIndex, regex.lastIndex - matches[0].length));

    const component = (
      <Link
        key={`${lastIndex}_${matches[0]}`}
        href={formatter(matches[0])}
        target={"_blank"}
      >
        {matches[0]}
      </Link>
    );

    result.push(
      styleXArray.length ? (
        <Text as={"span"} styleXArray={styleXArray}>
          {component}
        </Text>
      ) : (
        component
      ),
    );
    lastIndex = regex.lastIndex;
  }
  if (result.length) result.push(str.substring(lastIndex));

  return result;
};

export type TextWithLinksProps = TextProps & {
  expandable?: boolean;
  linkStyleXArray?: StyleXArray;
};

export const TextWithLinks = ({
  children,
  expandable = false,
  linkStyleXArray = [],
  ...props
}: TextWithLinksProps) => {
  const determinedChildren = useMemo(() => {
    if (typeof children !== "string") return children; // we don't support nesting

    const result = [];

    const urlRegex = /https?:\/\/.*?(?=\s|$)/g;
    const deeplinkRegex = /hallow:\/\/.*?(?=\s|$)/g;
    const deeplinkFormatter = (str: string) =>
      getURLFromDeeplink({ deeplink: str });

    result.push(...makeLinks(children, urlRegex, linkStyleXArray));

    if (result.length === 0) {
      result.push(
        ...makeLinks(
          children,
          deeplinkRegex,
          linkStyleXArray,
          deeplinkFormatter,
        ),
      );
    } else {
      for (let i = 0; i < result.length; i++) {
        if (typeof result[i] !== "string") continue;

        const newPieces = makeLinks(
          result[i],
          deeplinkRegex,
          linkStyleXArray,
          deeplinkFormatter,
        );
        if (newPieces.length) {
          result.splice(i, 1, ...newPieces);
        }
      }
    }

    return result.length ? result : children;
  }, [children]);

  if (expandable)
    return (
      <ExpandableText {...props}>
        <>{determinedChildren}</>
      </ExpandableText>
    );

  return (
    <Text {...props}>
      <>{determinedChildren}</>
    </Text>
  );
};
