import type { Me } from "../../src";

export const MOCK_DATA_GET_ME: Me = {
  // partial user returned from GET /me
  background_track_id: 10841,
  consent: [1, 2, 3],
  country: "US",
  email: "hallowherald+test@hallow.app",
  guide: "Francis",
  goal: null,
  id: 100007,
  phone: null,
  image_url: "https://images-dev.hallow.app/avatars/5.png",
  language: "en",
  last_name: "Herald",
  longest_streak: 47,
  name: "Hallow",
  notifications_enabled: true,
  prayer_goals: null,
  sharing_to: null,
  streak: 40,
  subscription: {
    id: 10000,
    user_id: 10000,
    type: "stripe",
    product: "yearly_full_subscription",
    start_date: "2023-05-24T13:21:35.000Z",
    end_date: "2025-06-08T00:00:00.000Z",
    is_renewing: true,
    is_trial: false,
    billing_issues_at: null,
    is_owner: true,
    promo: null,
    trial_ends_at: "2023-06-07T00:00:00.000Z",
    latest_receipt: null,
    price_id: "JEEG4V",
    display_metadata: {
      title: "Annual Plan",
      description: "Renewing on 6/7/2025",
      details: "Subscribed through hallow.com",
    },
  },
  sync_health: false,
  sync_journals: false,
  time_in_prayer: 70,
  timezone: "America/Los_Angeles",
};
