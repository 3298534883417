import React from "react";

import type { BasicIconProps as IconProps } from "./Icon";
import { IconWrapper } from "./Icon";

type BaseTabBarIcon = (props: IconProps) => React.ReactElement<any>;

export interface TabBarIcon extends BaseTabBarIcon {
  Filled: BaseTabBarIcon;
  Outline: BaseTabBarIcon;
}

export const HomeIcon: TabBarIcon = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.876 5.089l-7 5.563A4.955 4.955 0 004 14.531v8.502C4 25.776 6.239 28 9 28h14c2.761 0 5-2.224 5-4.967V14.53c0-1.508-.69-2.935-1.877-3.878l-7-5.563a5.026 5.026 0 00-6.247 0zM6 14.53c0-.905.414-1.762 1.126-2.327l7-5.564a3.015 3.015 0 013.748 0l7 5.563A2.973 2.973 0 0126 14.531v8.502a2.99 2.99 0 01-3 2.98H9a2.99 2.99 0 01-3-2.98V14.53zm5.679 3.485a1.005 1.005 0 00-1.414 0 .989.989 0 000 1.405c3.254 3.233 8.53 3.233 11.785 0a.989.989 0 000-1.405 1.005 1.005 0 00-1.414 0 6.363 6.363 0 01-8.957 0z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

HomeIcon.Outline = HomeIcon;

HomeIcon.Filled = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.876 10.652l7-5.563a5.026 5.026 0 016.248 0l7 5.563A4.955 4.955 0 0128 14.53v8.503C28 25.776 25.76 28 23 28H9c-2.761 0-5-2.224-5-4.967V14.53c0-1.51.69-2.936 1.876-3.879zm5.803 7.364a1.005 1.005 0 00-1.414 0 .989.989 0 000 1.405c3.254 3.233 8.53 3.233 11.785 0a.989.989 0 000-1.405 1.005 1.005 0 00-1.414 0 6.363 6.363 0 01-8.957 0z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const SearchIcon = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14a7 7 0 1114 0 7 7 0 01-14 0zm7-9a9 9 0 105.618 16.032l5.675 5.675a1 1 0 001.414-1.414l-5.675-5.675A9 9 0 0014 5z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

SearchIcon.Outline = SearchIcon;

SearchIcon.Filled = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.654 21.482a9.5 9.5 0 112.828-2.828l5.432 5.432a2 2 0 11-2.828 2.828l-5.432-5.432zM13.5 20a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

SearchIcon.Small = (props: IconProps) => (
  <IconWrapper size={24} viewBox="0 0 24 24" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4a7 7 0 104.192 12.606l4.1 4.101a1 1 0 001.415-1.414l-4.1-4.1A7 7 0 0011 4zm-5 7a5 5 0 1110 0 5 5 0 01-10 0z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const BibleIcon = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10a6 6 0 016-6h12a6 6 0 016 6v12a6 6 0 01-6 6H10a5.99 5.99 0 01-2.027-.351l-.013-.005A6.002 6.002 0 014 22V10zm5.897 15.999a3.992 3.992 0 01-1.142-.197A1.5 1.5 0 019.5 23H22a5.978 5.978 0 004-1.528V22a4 4 0 01-4 4H10l-.103-.001zM26 17a4 4 0 01-4 4H9.5a3.501 3.501 0 00-3.286 2.293A3.995 3.995 0 016 22V10a4 4 0 014-4h12a4 4 0 014 4v7zM16 9a1 1 0 011 1v1.5h1.5a1 1 0 110 2H17V17a1 1 0 11-2 0v-3.5h-1.5a1 1 0 110-2H15V10a1 1 0 011-1z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

BibleIcon.Outline = BibleIcon;

BibleIcon.Filled = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4a6 6 0 00-6 6v12a6 6 0 006 6h12a6 6 0 006-6V10a6 6 0 00-6-6H10zm6 5a1 1 0 011 1v1.5h1.5a1 1 0 110 2H17V17a1 1 0 11-2 0v-3.5h-1.5a1 1 0 110-2H15V10a1 1 0 011-1zm6 14a5.978 5.978 0 004-1.528V22a4 4 0 01-4 4H10c-.436 0-.853-.07-1.244-.197A1.5 1.5 0 019.5 23H22z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const CommunityIcon = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.494 5.625a2.885 2.885 0 00-3.588 0l-2.625 2.087a2.858 2.858 0 00-1.08 2.237l-.001 3.188A2.869 2.869 0 0013.075 16h5.25a2.869 2.869 0 002.875-2.863V9.95c0-.872-.4-1.695-1.081-2.237l-2.625-2.087zM15.15 7.191a.885.885 0 011.098 0l2.625 2.086a.858.858 0 01.326.672v3.188c0 .47-.386.863-.875.863h-5.25a.869.869 0 01-.875-.863V9.95c0-.26.119-.507.326-.672l2.625-2.086zM7.706 17.625a2.884 2.884 0 013.588 0l2.624 2.087A2.858 2.858 0 0115 21.949v3.188A2.869 2.869 0 0112.125 28h-5.25A2.869 2.869 0 014 25.137V21.95c0-.872.4-1.695 1.081-2.237l2.625-2.087zm2.343 1.566a.884.884 0 00-1.098 0l-2.625 2.086A.858.858 0 006 21.95v3.188c0 .47.386.863.875.863h5.25c.49 0 .875-.392.875-.863V21.95a.858.858 0 00-.326-.672l-2.625-2.086zm14.245-1.566a2.884 2.884 0 00-3.588 0l-2.625 2.087A2.858 2.858 0 0017 21.949v3.188A2.869 2.869 0 0019.875 28h5.25A2.869 2.869 0 0028 25.137V21.95c0-.872-.4-1.695-1.082-2.237l-2.624-2.087zm-2.343 1.566a.884.884 0 011.098 0l2.625 2.086a.858.858 0 01.326.672v3.188c0 .47-.386.863-.875.863h-5.25a.869.869 0 01-.875-.863V21.95c0-.26.119-.507.326-.672l2.625-2.086z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

CommunityIcon.Outline = CommunityIcon;

CommunityIcon.Filled = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.494 4.625a2.885 2.885 0 00-3.588 0l-2.625 2.087a2.858 2.858 0 00-1.08 2.237l-.001 3.188A2.869 2.869 0 0013.075 15h5.25a2.869 2.869 0 002.875-2.863V8.95c0-.872-.4-1.695-1.081-2.237l-2.625-2.087zm-6.2 12a2.884 2.884 0 00-3.588 0l-2.625 2.087A2.858 2.858 0 004 20.949v3.188A2.869 2.869 0 006.875 27h5.25A2.869 2.869 0 0015 24.137V20.95c0-.872-.4-1.695-1.082-2.237l-2.624-2.087zm13 0a2.884 2.884 0 00-3.588 0l-2.625 2.087A2.858 2.858 0 0017 20.949v3.188A2.869 2.869 0 0019.875 27h5.25A2.869 2.869 0 0028 24.137V20.95c0-.872-.4-1.695-1.082-2.237l-2.624-2.087z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const GroupsIcon = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 10.268a6 6 0 11-12 0 6 6 0 0112 0zm-6 4a4 4 0 100-8 4 4 0 000 8zm-1 8a6 6 0 11-12 0 6 6 0 0112 0zm-6 4a4 4 0 100-8 4 4 0 000 8zm14 2a6 6 0 100-12 6 6 0 000 12zm4-6a4 4 0 11-8 0 4 4 0 018 0z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

GroupsIcon.Outline = GroupsIcon;

GroupsIcon.Filled = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 16a6 6 0 100-12 6 6 0 000 12zM9 28a6 6 0 100-12 6 6 0 000 12zm14 0a6 6 0 100-12 6 6 0 000 12z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const MeIcon = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12zm10-12c0 5.523-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6s10 4.477 10 10zm-5.05 4.95a1 1 0 10-1.415-1.415 5 5 0 01-7.07 0 1 1 0 00-1.415 1.415 7 7 0 009.9 0z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

MeIcon.Outline = MeIcon;

MeIcon.Filled = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 16c0 6.627-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4s12 5.373 12 12zm-7.05 4.95a1 1 0 10-1.415-1.415 5 5 0 01-7.07 0 1 1 0 00-1.415 1.415 7 7 0 009.9 0z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);

export const DashboardIcon = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        d="M9 17a1 1 0 112 0v1a1 1 0 11-2 0v-1zM18 15a1 1 0 00-1 1v2a1 1 0 102 0v-2a1 1 0 00-1-1zM13 12a1 1 0 112 0v6a1 1 0 11-2 0v-6zM22 13a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.554 6c-.239 0-.523 0-.774.02-.29.024-.706.085-1.142.307a3 3 0 00-1.311 1.311A3.016 3.016 0 004.02 8.78C4 9.031 4 9.316 4 9.554v10.892c0 .238 0 .523.02.774.024.29.085.706.307 1.142a3 3 0 001.311 1.311c.436.222.852.283 1.142.307.251.02.535.02.774.02h16.892c.238 0 .523 0 .774-.02.29-.024.706-.085 1.142-.307a3 3 0 001.311-1.311c.222-.436.283-.852.307-1.142.02-.251.02-.535.02-.774V9.554c0-.238 0-.523-.02-.774a3.015 3.015 0 00-.307-1.142 3 3 0 00-1.311-1.311 3.016 3.016 0 00-1.142-.307C24.969 6 24.685 6 24.446 6H7.554zM6 9.6c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C6.76 8 7.04 8 7.6 8h16.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C26 8.76 26 9.04 26 9.6v10.8c0 .56 0 .84-.109 1.054a1 1 0 01-.437.437C25.24 22 24.96 22 24.4 22H7.6c-.56 0-.84 0-1.054-.109a1 1 0 01-.437-.437C6 21.24 6 20.96 6 20.4V9.6z"
        fill={props.fill}
      />
      <path d="M10 25a1 1 0 100 2h12a1 1 0 100-2H10z" fill={props.fill} />
    </svg>
  </IconWrapper>
);

DashboardIcon.Outline = DashboardIcon;

DashboardIcon.Filled = (props: IconProps) => (
  <IconWrapper size={32} viewBox="0 0 32 32" {...props}>
    <svg>
      <path
        d="M9 26a1 1 0 011-1h12a1 1 0 110 2H10a1 1 0 01-1-1z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78 6.02C7.031 6 7.315 6 7.554 6h16.892c.238 0 .523 0 .774.02.29.024.706.085 1.142.307a3 3 0 011.311 1.311c.222.436.283.852.307 1.142.02.251.02.536.02.774v10.892c0 .238 0 .523-.02.774-.024.29-.085.706-.307 1.142a3 3 0 01-1.311 1.311 3.017 3.017 0 01-1.142.307c-.251.02-.535.02-.774.02H7.554c-.239 0-.523 0-.774-.02a3.017 3.017 0 01-1.142-.307 3 3 0 01-1.311-1.311 3.016 3.016 0 01-.307-1.142C4 20.969 4 20.685 4 20.446V9.554c0-.238 0-.523.02-.774.024-.29.085-.706.307-1.142a3 3 0 011.311-1.311A3.016 3.016 0 016.78 6.02zM14 11a1 1 0 00-1 1v6a1 1 0 102 0v-6a1 1 0 00-1-1zm3 5a1 1 0 112 0v2a1 1 0 11-2 0v-2zm-7 0a1 1 0 00-1 1v1a1 1 0 102 0v-1a1 1 0 00-1-1zm11-2a1 1 0 112 0v4a1 1 0 11-2 0v-4z"
        fill={props.fill}
      />
    </svg>
  </IconWrapper>
);
