"use client";

import type { DependencyList, Dispatch, Ref, SetStateAction } from "react";
import { useEffect, useState } from "react";

export type UseIntersectionObserverRefStateProps = {
  deps?: DependencyList;
  ref: Ref<any>;
  rootRef?: Ref<any>;
  continuous?: boolean;
};

export const useIntersectionObserverRefState = ({
  deps,
  ref,
  rootRef,
  continuous = true,
}: UseIntersectionObserverRefStateProps) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([intersection]) => {
        if (continuous || !state) {
          setState(intersection.isIntersecting);
        }
      },
      {
        root: rootRef?.["current"],
      },
    );
    if (ref["current"]) {
      observer.observe(ref["current"]);
    }
    return () => {
      if (ref["current"]) {
        observer.unobserve(ref["current"]);
      }
    };
  }, [deps, ref]);

  return [state, setState] as [boolean, Dispatch<SetStateAction<boolean>>];
};
