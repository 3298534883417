import type { GiftDetails } from "../../src";
import { MOCK_DATA_GET_ME } from "./mockDataGetMe";

export const MOCK_VALID_GIFT_CARD: GiftDetails = {
  purchaser: MOCK_DATA_GET_ME,
  name: "1-Year Gift Subscription",
  trial_days: 365,
};

export const MOCK_ANONYMOUS_GIFT_CARD: GiftDetails = {
  ...MOCK_VALID_GIFT_CARD,
  purchaser: null,
};

export const MOCK_UNNAMED_SENDER: GiftDetails = {
  ...MOCK_VALID_GIFT_CARD,
  purchaser: {
    ...MOCK_VALID_GIFT_CARD.purchaser,
    name: null,
    last_name: null,
  },
};

export const MOCK_INVALID_GIFT_CARD: GiftDetails = {
  ...MOCK_VALID_GIFT_CARD,
  redemption_error: {
    status: "error",
    code: 3047,
    error:
      "The provided promo code has been redeemed more than the allowable amount and is no longer valid.",
  },
};

export const MOCK_INVALID_USER: GiftDetails = {
  ...MOCK_VALID_GIFT_CARD,
  redemption_error: {
    status: "error",
    code: 3003,
    error:
      "This account cannot redeem the gift, because it already has an active recurring subscription.",
  },
};

export const MOCK_3_MONTH_GIFT_CARD: GiftDetails = {
  ...MOCK_VALID_GIFT_CARD,
  name: "3-Month Gift Subscription",
  trial_days: 90,
};
