import type { CheckoutFormDetails, CheckoutProps } from "./Checkout";

type RedirectCheckoutProps = CheckoutProps;
type RedirectCheckoutSubmitProps = Partial<CheckoutFormDetails> & {
  paymentMethod: string;
};

export const useRedirectCheckout = (props: RedirectCheckoutProps) => {
  return async ({
    email,
    document,
    paymentMethod,
  }: RedirectCheckoutSubmitProps) => {
    await props.onRedirect?.({
      paymentMethod: paymentMethod,
      email,
      document,
    });
  };
};
