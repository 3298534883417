"use client";

import { Link, useRouter } from "@packages/i18n";

import type { IconButtonProps, IconElement } from "../../../_base";
import { ArrowLeftIcon, IconButton } from "../../../_base";

export type BackButtonProps = Omit<IconButtonProps, "icon"> & {
  icon?: IconElement;
  backHref?: string;
};

export const BackButton = ({
  icon = <ArrowLeftIcon />,
  backHref,
  onClick,
  ...props
}: BackButtonProps) => {
  const router = useRouter();
  return (
    <IconButton
      icon={icon}
      onClick={(e) => {
        if (onClick) onClick(e);
        !backHref && router.back();
      }}
      asChild={!!backHref}
      {...props}
    >
      {backHref && <Link href={backHref} />}
    </IconButton>
  );
};
