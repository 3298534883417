import type { PropsWithChildren } from "react";

import type { WithStylexArray } from "../../../types";
import { Text } from "../../_base";

type AuthErrorProps = WithStylexArray<PropsWithChildren>;

export const AuthError = ({ styleXArray, children }: AuthErrorProps) => (
  <Text as={"p"} type={"detail"} size={"xl"} styleXArray={styleXArray}>
    {children}
  </Text>
);
