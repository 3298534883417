"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import type { PaymentResult } from "@packages/sdk";
import { PaymentElement } from "@stripe/react-stripe-js";
import type { UseFormReturn } from "react-hook-form";

type StripePaymentProps = {
  form: UseFormReturn<Partial<PaymentResult>, any, undefined>;
  onSubmit: (
    params: Partial<PaymentResult> & { platform?: string },
  ) => Promise<void>;
  onSelect?: (platform: "stripe") => void;
};

export const StripePaymentElement = ({
  form,
  onSubmit,
  onSelect,
}: StripePaymentProps) => {
  const analytics = useAnalytics();

  const email = form.watch("email");
  const name = form.watch("name");

  return (
    <PaymentElement
      options={{
        defaultValues: {
          billingDetails: {
            email,
            name,
          },
        },
        layout: {
          defaultCollapsed: true,
          radios: false,
          spacedAccordionItems: true,
          type: "accordion",
        },
      }}
      onChange={(event) => {
        // If not open we dont want it to trigger
        if (event.collapsed) return;
        onSelect("stripe");

        if (
          event.value.type === "apple_pay" ||
          event.value.type === "google_pay"
        ) {
          analytics.track({
            event:
              event.value.type === "apple_pay"
                ? HallowAnalyticsEvent.TappedPaymentApplePay
                : HallowAnalyticsEvent.TappedPaymentGooglePay,
          });
          onSubmit({ email, name, platform: "stripe" });
        }
      }}
    />
  );
};
