"use client";

import type { RefObject } from "react";
import { createContext, useContext, useRef } from "react";

const completionCacheContext = createContext<{
  completedPrayers: RefObject<number[] | null>;
  complete: (id: number) => void;
}>({ completedPrayers: { current: [] }, complete: () => {} });

export const useCompletionCache = () => useContext(completionCacheContext);

export const CompletionCacheProvider = ({ children }) => {
  const list = useRef<number[]>([]);

  const complete = (id: number) => {
    list.current.push(id);
  };

  return (
    <completionCacheContext.Provider
      value={{ completedPrayers: list, complete }}
    >
      {children}
    </completionCacheContext.Provider>
  );
};
