import type { AnalyticsEvent } from "@packages/analytics";
import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import type { PaymentResult } from "@packages/sdk";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import { useAlert } from "../../_base";
import type { CheckoutProps } from "./Checkout";

type StripeCheckoutProps = Pick<
  CheckoutProps,
  "total" | "handleSubscription" | "onSubmit"
>;
type StripeCheckoutSubmitProps = Pick<PaymentResult, "email" | "name">;

export const useStripeCheckout = (props: StripeCheckoutProps) => {
  const elements = useElements();
  const stripe = useStripe();

  const alert = useAlert();
  const t = useTranslations();
  const analytics = useAnalytics();

  return async ({ email, name }: StripeCheckoutSubmitProps) => {
    const event: AnalyticsEvent = {
      event: HallowAnalyticsEvent.TappedSubmitStripe,
      properties: { total: props.total },
    };
    if (props.handleSubscription?.promoCode)
      event.properties.promo_code = props.handleSubscription.promoCode;
    analytics.track(event);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      if (submitError.code === "incomplete") return;
      alert.show({
        title: t("general_word_error"),
        description: submitError.message,
      });
      return;
    }

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: { email, name },
        },
      });

    if (paymentMethodError) {
      alert.show({
        title: t("general_word_error"),
        description: paymentMethodError.message,
      });
      return;
    }

    const { address } = paymentMethod.billing_details;

    const paymentResult: PaymentResult = {
      address,
      email,
      name,
      paymentMethod: paymentMethod.id,
      paymentPlatform: "stripe",
    };

    await props.onSubmit(paymentResult);
  };
};
