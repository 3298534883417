"use client";

import type { LSFontFamily } from "@packages/sdk";
import type { Dispatch, RefObject, SetStateAction } from "react";
import { createContext } from "react";

import { Theme } from "../types";

export type ThemeContext = {
  setTheme: Dispatch<SetStateAction<Theme>>;
  theme: Theme;
  fontStyle: LSFontFamily;
  setFontStyle: Dispatch<SetStateAction<LSFontFamily>>;
  letterSpacing: boolean;
  setLetterSpacing: Dispatch<SetStateAction<boolean>>;
  wordSpacing: boolean;
  setWordSpacing: Dispatch<SetStateAction<boolean>>;
  themeContainerRef: RefObject<HTMLDivElement | null>;
};

export const themeContext = createContext<ThemeContext>({
  setTheme: () => {},
  theme: Theme.LIGHT,
  fontStyle: "normal",
  setFontStyle: () => {},
  letterSpacing: false,
  setLetterSpacing: () => {},
  wordSpacing: false,
  setWordSpacing: () => {},
  themeContainerRef: null as unknown as RefObject<HTMLDivElement | null>,
});
