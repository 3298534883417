"use client";

import { useTranslations } from "@packages/i18n";
import {
  hasExpired,
  isMobileSubscription,
  useRequestBilling,
  useRequestMe,
  useRequestSubscription,
} from "@packages/sdk";
import { useForm } from "react-hook-form";

import { useAlert } from "../../_base";
import type {
  CheckoutActions,
  CheckoutFormDetails,
  CheckoutProps,
} from "./Checkout";
import { Checkout } from "./Checkout";

export type CheckoutSubscriptionActions = {
  successRedirectUrl?: string;
  failureRedirectUrl?: string;
  onSuccess: CheckoutActions["onSubmit"];
  onChange?: CheckoutActions["onChange"];
};

export type CheckoutSubscription = {
  priceId: string;
  promoCode?: string;
} & CheckoutSubscriptionActions &
  Omit<
    CheckoutProps,
    "mode" | "handleSubscription" | "onSubmit" | "onRedirect" | "form"
  >;

export const CheckoutSubscription = ({
  onSuccess,
  priceId,
  promoCode,
  successRedirectUrl,
  failureRedirectUrl,
  ...props
}: CheckoutSubscription) => {
  const alert = useAlert();
  const t = useTranslations();

  const {
    query: subscription,
    mutationSubscribe: subscribe,
    mutationUpgradeMobile: upgradeMobile,
  } = useRequestSubscription();
  const { mutationInitSubscription: billingRedirect } = useRequestBilling();
  const { query: me } = useRequestMe();

  const form = useForm<Partial<CheckoutFormDetails>>({
    defaultValues: props.defaults,
    shouldUseNativeValidation: true,
  });

  const handleOnSubmit: CheckoutProps["onSubmit"] = async ({
    email,
    name,
    ...paymentResult
  }) => {
    const { email: formEmail, name: formName } = form.getValues();

    try {
      /**
       * If user has an active mobile subscription, upgrade it
       */
      if (
        isMobileSubscription(subscription.data) &&
        !hasExpired(subscription.data)
      ) {
        await upgradeMobile.mutateAsync({
          priceId,
          promoCode,
          email: formEmail || email,
          name: formName || name,
          ...paymentResult,
        });
      } else {
        await subscribe.mutateAsync({
          priceId,
          promoCode,
          email: formEmail || email,
          name: formName || name,
          ...paymentResult,
        });
      }

      await subscription.refetch();

      await onSuccess(paymentResult);
    } catch (error) {
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  const handleOnRedirect: CheckoutProps["onRedirect"] = async (
    paymentRedirect,
  ) => {
    try {
      const { redirect_url } = await billingRedirect.mutateAsync({
        priceId,
        promoCode,
        successRedirectUrl,
        failureRedirectUrl,
        email: me.data?.email,
        ...paymentRedirect,
      });
      window.location.href = redirect_url;
      return false;
    } catch (error) {
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  return (
    <Checkout
      mode="subscription"
      handleSubscription={{
        priceId,
        promoCode,
      }}
      onSubmit={handleOnSubmit}
      onRedirect={handleOnRedirect}
      form={form}
      {...props}
    />
  );
};
